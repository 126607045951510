import React from 'react';
import HomeComponent from '../../Components/Home';
const HomePage = ()=>{

    return(
        <>
            <HomeComponent />
        </>
    )
}

export default HomePage;